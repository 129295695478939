<template>
    <div class="board-input">
        <div class="mb-6"><p class="font-size-14 text-right"><span style="color: rgb(255, 0, 0);">*</span> 필수입력입니다</p></div>
        <div class="table-style table-style--default">
            <div class="table-style__row">
                <div class="table-style__th">센터</div>
                <div class="table-style__td">
                    <v-select v-model="question.center" :items="centerTypes" placeholder="전체" clearable outlined hide-details></v-select>
                    <!-- <select v-model="question.center" class="select">
                        <option :value="null" disabled>센터명</option>
                        <option v-for="(center, index) in centerTypes" :key="index" :value="center">{{ center }}</option>
                    </select> -->
                </div>
            </div>
            <div class="table-style__row">
                <div class="table-style__th">문의유형<span class="red--text">*</span></div>
                <div class="table-style__td">
                    <v-select v-model="question.type" :items="questionTypes" placeholder="선택" outlined hide-details></v-select>
                    <!-- <select v-model="question.type" class="select">
                        <option :value="null" disabled>문의유형</option>
                        <option v-for="(type, index) in questionTypes" :key="index" :value="type">{{ type }}</option>
                    </select> -->
                </div>
            </div>
            <div class="table-style__row">
                <div class="table-style__th">제목<span class="red--text">*</span></div>
                <div class="table-style__td">
                    <v-text-field v-model="question.subject" :placeholder="$t('common.title')" outlined hide-details></v-text-field>
                </div>
            </div>

            <div class="table-style__row">
                <div class="table-style__th">내용<span class="red--text">*</span></div>
                <div class="table-style__td">
                    <v-textarea v-model="question.content" outlined hide-details height="250"></v-textarea>
                </div>
            </div>
            <div class="table-style__row">
                <div class="table-style__th">첨부파일</div>
                <div class="table-style__td">
                    <!-- <v-item v-for="file, index in question?.files" :key="index">
                        <v-btn v-model="question.files" outlined hide-details @click="upload(file.name, file.path)">파일올리기</v-btn>
                        <span>* 최대 50MB까지 올리실 수 있습니다.</span>
                    </v-item> -->
                    <v-file-input v-model="files[0]" outlined hide-details persistent-placeholder />
                    <div class="font-size-14 mt-4" style="color:#ff0000;">최대 50MB까지 올리실 수 있습니다.</div>
                </div>
            </div>
        </div>

        <!-- 하단버튼 -->
        <div class="v-btn-group--bottom">
            <v-row justify="center" class="row--small">
                <v-col cols="6" sm="auto">
                    <v-btn large outlined block color="grey-d6" class="w-md-240px" @click="cancel">{{$t('common.취소')}}</v-btn>
                </v-col>
                <v-col cols="6" sm="auto">
                    <v-btn large block color="primary" class="w-md-240px" @click="save">{{$t('common.confirm')}}</v-btn>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import api from "@/api";
import download from "downloadjs";

export default {
	components: {
	},
    data() {
        return {
            // files: [{ name: "" },{ name: "" },{ name: "" }],
            // fileName: "",
            question: {
                _id: undefined,
                center: null,
                type: null,
                subject: null,
                content: null,
                files: [],
            },
            files: [],
            questionTypes: [
                "회원정보 문의",
                "주문/결제 문의",
                "상품 문의",
                "배송 문의",
                "교환/반품 문의",
                "이벤트/혜택 문의",
                "서비스 개선 의견",
                "기타문의"
            ],
            centerTypes: [
                "잇다",
                "날다",
                "하다"
            ]
        }
    },
	methods : {
        fileChange(index){
            this.files[index].name = event.target.files[0].name;
        },
        cancel(){
            this.$router.go(-1);
        },
        validate(){
            try{
                var question = this.question;
                if(!question.type) throw new Error("문의유형을 선택해주세요");
                if(!question.subject) throw new Error("문의제목을 입력해주세요");
                if(!question.content) throw new Error("문의내용을 입력해주세요");

                return true;
            }
            catch(error){
                alert(error.message);
            }
            return false;
        },
        async save(){
            if(this.validate()){
                let { question } = await api.v1.center.questions.post(this.question);
                console.log(question._id, "Dddd");

                for(let file of this.files){
                    let index = this.files.indexOf(file);
                    if(file) await api.v1.center.questions.files.post(question._id, index, file)
                }
                alert("문의가 등록되었습니다");
                this.$router.go(-1);
            }
        },
        download(path, rename){
            api.getResource(path).then(file=> download(file, rename));
        }
	},
}
</script>
