var render = function render(){
  var _vm$question$files, _vm$question$files2, _vm$question$files2$, _vm$question$files3, _vm$question$files3$;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.question ? _c('div', {
    staticClass: "board-container"
  }, [_c('table', {
    staticClass: "board-view"
  }, [_c('tr', [_c('th', {
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v(_vm._s(_vm.question.subject))])]), _c('tr', [_c('td', {
    attrs: {
      "colspan": "2"
    }
  }, [_c('v-row', {
    staticClass: "row--small"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_vm._v("센터 : " + _vm._s(_vm.question.center || '전체'))])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_vm._v("유형 : " + _vm._s(_vm.question.type))])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("common.date_created")) + " : " + _vm._s(_vm.question.createdAt.toDate()))])])], 1)], 1)]), (_vm$question$files = _vm.question.files) !== null && _vm$question$files !== void 0 && _vm$question$files[0] ? _c('tr', [_c('td', {
    attrs: {
      "colspan": "2"
    }
  }, [_c('v-row', {
    staticClass: "row--small"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_vm._v(" 첨부파일 : "), _c('v-chip', {
    staticClass: "ml-4 font-size-14",
    attrs: {
      "outlined": "",
      "label": "",
      "download": "",
      "href": (_vm$question$files2 = _vm.question.files) === null || _vm$question$files2 === void 0 ? void 0 : (_vm$question$files2$ = _vm$question$files2[0]) === null || _vm$question$files2$ === void 0 ? void 0 : _vm$question$files2$.path
    }
  }, [_c('v-icon', {
    staticClass: "mr-6",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-file")]), _vm._v(" " + _vm._s((_vm$question$files3 = _vm.question.files) === null || _vm$question$files3 === void 0 ? void 0 : (_vm$question$files3$ = _vm$question$files3[0]) === null || _vm$question$files3$ === void 0 ? void 0 : _vm$question$files3$.name))], 1)], 1)])], 1)], 1)]) : _vm._e(), _c('tr', [_c('td', {
    attrs: {
      "colspan": "2"
    }
  }, [_c('v-row', {
    staticClass: "row--small"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t("common.answer_status")) + " : "), _vm.question.reply ? _c('span', {
    staticClass: "primary--text"
  }, [_vm._v(_vm._s(_vm.$t("common.answer_complete")))]) : _c('span', {
    staticClass: "green--text text--lighten-1"
  }, [_vm._v(_vm._s(_vm.$t("common.unanswered")))])])])], 1)], 1)]), _c('tr', [_c('td', {
    staticClass: "content_box",
    attrs: {
      "colspan": "2"
    }
  }, [_vm.question.content ? _c('div', {
    staticClass: "py-md-20"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "w-30px w-md-40px font-weight-bold primary--text"
  }, [_vm._v("Q.")])]), _c('v-col', [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.question.content.replace(/\n/g, '<br>'))
    }
  })])], 1)], 1) : _vm._e(), _vm.question.reply ? _c('div', {
    staticClass: "py-md-20"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "w-30px w-md-40px font-weight-bold primary--text"
  }, [_vm._v("A.")])]), _c('v-col', [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.question.reply.replace(/\n/g, '<br>'))
    }
  })])], 1)], 1) : _vm._e()])])]), _c('table', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "board-view-navi"
  }, [_vm._m(0), _vm._m(1)]), _c('div', {
    staticClass: "board-bottom"
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "justify": "end"
    }
  }, [_c('v-col', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "justify": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-100px",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("수정")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-100px",
    attrs: {
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v("삭제")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-100px",
    attrs: {
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v("답글")])], 1)], 1)], 1), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-140px",
    attrs: {
      "outlined": "",
      "color": "grey-d6"
    },
    on: {
      "click": _vm.list
    }
  }, [_vm._v(_vm._s(_vm.$t("common.list")))])], 1)], 1)], 1)]) : _vm._e();

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', [_c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("이전글")]), _c('td', [_c('a', {
    attrs: {
      "href": ""
    }
  }, [_vm._v("이전 게시글 제목입니다.")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', [_c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("다음글")]), _c('td', [_c('a', {
    attrs: {
      "href": ""
    }
  }, [_vm._v("다음 게시글 제목입니다.")])])]);

}]

export { render, staticRenderFns }