var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board-container"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "board-search"
  }, [_c('div', {
    staticClass: "board-search__box"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filter.searchValue,
      expression: "filter.searchValue"
    }],
    staticClass: "board-search__input",
    attrs: {
      "type": "text",
      "name": "",
      "value": ""
    },
    domProps: {
      "value": _vm.filter.searchValue
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.filter, "searchValue", $event.target.value);
      }
    }
  }), _c('button', {
    staticClass: "board-search__button",
    attrs: {
      "type": "submit",
      "name": "button"
    },
    on: {
      "click": _vm.search
    }
  })]), _c('div', {
    staticClass: "board-search__select"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filter.type,
      expression: "filter.type"
    }],
    staticClass: "board-search__selectbox",
    attrs: {
      "name": ""
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.filter, "type", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "",
      "disabled": "",
      "selected": ""
    }
  }, [_vm._v("문의항목을 선택해주세요.")]), _vm._l(_vm.questionTypes, function (type, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": type
      }
    }, [_vm._v(_vm._s(type))]);
  })], 2)])]), _c('table', {
    staticClass: "board-list"
  }, [_c('thead', {
    staticClass: "d-none d-lg-table-header"
  }, [_c('tr', [_c('th', {
    staticClass: "board-list__txt d-none d-lg-table-cell",
    staticStyle: {
      "width": "10%"
    }
  }, [_vm._v(_vm._s(_vm.$t("common.number")))]), _c('th', {
    staticClass: "board-list__txt",
    staticStyle: {
      "width": "15%"
    }
  }, [_vm._v("센터")]), _c('th', {
    staticClass: "board-list__txt",
    staticStyle: {
      "width": "15%"
    }
  }, [_vm._v("유형")]), _c('th', {
    staticClass: "board-list__tit",
    staticStyle: {
      "width": "60%"
    }
  }, [_vm._v(_vm._s(_vm.$t("common.inquiry")))]), _c('th', {
    staticClass: "board-list__txt",
    staticStyle: {
      "width": "15%"
    }
  }, [_vm._v(_vm._s(_vm.$t("common.date_created")))]), _c('th', {
    staticClass: "board-list__txt",
    staticStyle: {
      "width": "15%"
    }
  }, [_vm._v(_vm._s(_vm.$t("common.answer_status")))])])]), _c('tbody', _vm._l(_vm.questions, function (question, index) {
    return _c('tr', {
      key: question._id,
      on: {
        "click": function ($event) {
          return _vm.details(question);
        }
      }
    }, [_c('td', {
      staticClass: "board-list__txt d-none d-lg-table-cell",
      attrs: {
        "align": "center"
      }
    }, [_c('span', [_vm._v(_vm._s((_vm.page - 1) * _vm.limit + index + 1))])]), _c('td', {
      staticClass: "board-list__txt board-list__txt--first",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s(question.center || "전체") + " ")]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s(question.type) + " ")]), _c('td', {
      staticClass: "board-list__tit",
      attrs: {
        "align": "center"
      }
    }, [_c('div', {
      staticClass: "ellip"
    }, [_c('span', [_vm._v(_vm._s(question.subject))])])]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s(question.createdAt.toDate()) + " ")]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [question.reply ? _c('strong', {
      staticClass: "primary--text"
    }, [_vm._v(_vm._s(_vm.$t("common.answer_complete")))]) : _c('strong', {
      staticClass: "green--text text--lighten-1"
    }, [_vm._v(_vm._s(_vm.$t("common.unanswered")))])])]);
  }), 0)]), _c('div', {
    staticClass: "board-bottom"
  }, [_c('v-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "row--x-small",
    attrs: {
      "justify": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-140px",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.write
    }
  }, [_vm._v(_vm._s(_vm.$t("common.writing")))])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-140px",
    attrs: {
      "outlined": "",
      "color": "grey-d6"
    }
  }, [_vm._v(_vm._s(_vm.$t("common.list")))])], 1)], 1), _c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "justify": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-140px",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.write
    }
  }, [_vm._v(_vm._s(_vm.$t("common.writing")))])], 1)], 1)], 1), _c('pagination-component', {
    attrs: {
      "count": _vm.pageCount
    },
    on: {
      "input": _vm.search
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }