<template>
    <div v-if="question" class="board-container">
        <table class="board-view">
            <tr>
                <th colspan="2">{{ question.subject }}</th>
            </tr>
            <tr>
                <td colspan="2">
                    <v-row class="row--small">
                        <v-col cols="auto">
                            <span>센터 : {{ question.center || '전체' }}</span>
                        </v-col>
                        <v-col cols="auto">
                            <span>유형 : {{ question.type }}</span>
                        </v-col>
                        <v-col cols="auto">
                            <span>{{$t("common.date_created")}} : {{ question.createdAt.toDate() }}</span>
                        </v-col>
                    </v-row>
                </td>
            </tr>
            <tr v-if="question.files?.[0]">
                <td colspan="2">
                    <v-row class="row--small">
                        <v-col cols="auto">
                            <span >
                                첨부파일 :
                                <v-chip outlined label download :href="question.files?.[0]?.path" class="ml-4 font-size-14"> <v-icon small class="mr-6">mdi-file</v-icon> {{ question.files?.[0]?.name }}</v-chip>
                            </span>
                        </v-col>
                    </v-row>
                </td>
            </tr>
            <tr>
                <td colspan="2">
                    <v-row class="row--small">
                        <v-col cols="auto">
                            <span>
                                {{ $t("common.answer_status") }} : <span v-if="question.reply" class="primary--text">{{ $t("common.answer_complete") }}</span>
                                <span v-else class="green--text text--lighten-1">{{ $t("common.unanswered") }}</span>
                            </span>
                        </v-col>
                    </v-row>
                </td>
            </tr>
            <tr>
                <td colspan="2" class="content_box">
                    <div v-if="question.content" class="py-md-20">
                        <v-row no-gutters>
                            <v-col cols="auto"><div class="w-30px w-md-40px font-weight-bold primary--text">Q.</div></v-col>
                            <v-col>
                                <div v-html="question.content.replace(/\n/g,'<br>')"></div>
                            </v-col>
                        </v-row>
                    </div>
                    <div v-if="question.reply" class="py-md-20">
                        <v-row no-gutters>
                            <v-col cols="auto"><div class="w-30px w-md-40px font-weight-bold primary--text">A.</div></v-col>
                            <v-col>
                                <div v-html="question.reply.replace(/\n/g,'<br>')"></div>
                            </v-col>
                        </v-row>
                    </div>
                </td>
            </tr>
        </table>

        <table v-show="false" class="board-view-navi">
            <tr>
                <th width="20%">이전글</th>
                <td><a href="">이전 게시글 제목입니다.</a></td>
            </tr>
            <tr>
                <th width="20%">다음글</th>
                <td><a href="">다음 게시글 제목입니다.</a></td>
            </tr>
        </table>

        <div class="board-bottom">
            <v-row justify="end" class="row--x-small">
                <v-col v-show="false" cols="auto">
                    <v-row justify="end" class="row--x-small">
                        <v-col cols="auto">
                            <v-btn color="primary" class="w-100px">수정</v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn outlined color="primary" class="w-100px">삭제</v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn outlined color="primary" class="w-100px">답글</v-btn>
                        </v-col>
                    </v-row>
                </v-col>
                <v-spacer />
                <v-col cols="auto">
                    <v-btn outlined color="grey-d6" class="w-140px" @click="list">{{$t("common.list")}}</v-btn>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import api from "@/api";

export default {
    props: ["_id"],
    data(){
        return {
            question: null
        }
    },
	mounted() {
		this.init();
	},
	methods: {
		async init() {
            var { question } = await api.v1.center.questions.get({ _id: this.$props._id });
            this.question = question;
		},

        list(){
            this.$router.go(-1);
        },
	},
}
</script>
