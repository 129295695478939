var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board-input"
  }, [_vm._m(0), _c('div', {
    staticClass: "table-style table-style--default"
  }, [_c('div', {
    staticClass: "table-style__row"
  }, [_c('div', {
    staticClass: "table-style__th"
  }, [_vm._v("센터")]), _c('div', {
    staticClass: "table-style__td"
  }, [_c('v-select', {
    attrs: {
      "items": _vm.centerTypes,
      "placeholder": "전체",
      "clearable": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.question.center,
      callback: function ($$v) {
        _vm.$set(_vm.question, "center", $$v);
      },
      expression: "question.center"
    }
  })], 1)]), _c('div', {
    staticClass: "table-style__row"
  }, [_vm._m(1), _c('div', {
    staticClass: "table-style__td"
  }, [_c('v-select', {
    attrs: {
      "items": _vm.questionTypes,
      "placeholder": "선택",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.question.type,
      callback: function ($$v) {
        _vm.$set(_vm.question, "type", $$v);
      },
      expression: "question.type"
    }
  })], 1)]), _c('div', {
    staticClass: "table-style__row"
  }, [_vm._m(2), _c('div', {
    staticClass: "table-style__td"
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": _vm.$t('common.title'),
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.question.subject,
      callback: function ($$v) {
        _vm.$set(_vm.question, "subject", $$v);
      },
      expression: "question.subject"
    }
  })], 1)]), _c('div', {
    staticClass: "table-style__row"
  }, [_vm._m(3), _c('div', {
    staticClass: "table-style__td"
  }, [_c('v-textarea', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "height": "250"
    },
    model: {
      value: _vm.question.content,
      callback: function ($$v) {
        _vm.$set(_vm.question, "content", $$v);
      },
      expression: "question.content"
    }
  })], 1)]), _c('div', {
    staticClass: "table-style__row"
  }, [_c('div', {
    staticClass: "table-style__th"
  }, [_vm._v("첨부파일")]), _c('div', {
    staticClass: "table-style__td"
  }, [_c('v-file-input', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "persistent-placeholder": ""
    },
    model: {
      value: _vm.files[0],
      callback: function ($$v) {
        _vm.$set(_vm.files, 0, $$v);
      },
      expression: "files[0]"
    }
  }), _c('div', {
    staticClass: "font-size-14 mt-4",
    staticStyle: {
      "color": "#ff0000"
    }
  }, [_vm._v("최대 50MB까지 올리실 수 있습니다.")])], 1)])]), _c('div', {
    staticClass: "v-btn-group--bottom"
  }, [_c('v-row', {
    staticClass: "row--small",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-md-240px",
    attrs: {
      "large": "",
      "outlined": "",
      "block": "",
      "color": "grey-d6"
    },
    on: {
      "click": _vm.cancel
    }
  }, [_vm._v(_vm._s(_vm.$t('common.취소')))])], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-md-240px",
    attrs: {
      "large": "",
      "block": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(_vm._s(_vm.$t('common.confirm')))])], 1)], 1)], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-6"
  }, [_c('p', {
    staticClass: "font-size-14 text-right"
  }, [_c('span', {
    staticStyle: {
      "color": "rgb(255, 0, 0)"
    }
  }, [_vm._v("*")]), _vm._v(" 필수입력입니다")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-style__th"
  }, [_vm._v("문의유형"), _c('span', {
    staticClass: "red--text"
  }, [_vm._v("*")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-style__th"
  }, [_vm._v("제목"), _c('span', {
    staticClass: "red--text"
  }, [_vm._v("*")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-style__th"
  }, [_vm._v("내용"), _c('span', {
    staticClass: "red--text"
  }, [_vm._v("*")])]);

}]

export { render, staticRenderFns }